import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      mainBGColor: '#C45824',
    },
    secondary: {
      main: '#fff',
      mainBGColor: '#0E5C45', // En cas de modif, check pageStyles/contactStyles.js => le thème n'est pas reconnu dans les styles de pages
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#000',
    },
    neutral: {
      main: '#707070'
    }
  },
  typography: {
    h1: {
      color: '#fff',
      fontSize: 'clamp(20px, 3vw, 40px)',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '28px 0 31px 0'
    },
    h2: {
      color: '#fff',
      fontSize: 'clamp(18px, 2.5vw, 28px)',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: 'clamp(.8rem, 1.7vw, 18px)'
    },
    subtitle1: {
      fontSize: 'clamp(16px, 3vw, 23px)',
      lineHeight: 1.3
    },
    fontFamily: ['Roboto', 'sans-serif'].join(','), 
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  breakpoints: {
    values: {
      sm: 450,
      md: 960,
      lg: 1200
    },
  },
});

export default theme;
