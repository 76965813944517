import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  search : {
    height: "2rem"
  }, 
  searchIcon: { 
    height: '100%',
    position: 'absolute',
    display: 'flex',
    left: "0.25em"
    },    
  inputSearchbar: {
    marginLeft: '2.5rem',
    marginTop: '0.5rem', 
    border: 'none',
    outline: 'none',
    paddingLeft: `calc(${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),
    fontSize: '85%',
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: '80%'},
    '@media (max-width:1000px)': {
      marginTop: "0.3rem"
    }, 
  },
  }));