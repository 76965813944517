import { SERVICES } from './constants';

export const menuCategories = [
  {
    title: 'Qui sommes-nous ?',
    subCategories: [
      {
        name: "Notre projet, nos valeurs",
        subCatLink: "/conseil-administratif"
      },
      {
        name: "Histoire du SAIIL",
        subCatLink: "/histoire-du-saiil"
      }
    ]
  },
  {
    title: 'Actualités',
    link: "/actualites"
  },
  {
    title: SERVICES,
    subCategories: [
      {
        name: 'Cotation',
        subCatLink: "/cotations",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      },
      {
        name: "Qualité et sécurité des soins",
        subCatLink: "/tracabilite",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      },
      {
        name: "Formations",
        subCatLink: "/formations",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      },
      {
        name: "Juridique",
        subCatLink: "/juridique",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      },
    ]
  },
  {
    title: 'Mon installation',
    link: "/installation"
  },
  {
    title: 'Réglementation',
    link: "/reglementations"
  },
  {
    title: 'Contact',
    link: "/contact"
  },
];

export const registerLinks = [
  {
    title: "Pourquoi adhérer ?",
    link: "/adhesion",
    isOutsideLink: false
  },
  {
    title: "Espace adhérent",
    link: `${process.env.GATSBY_ADHERENT_URL}/login`,
    isOutsideLink: true,
    buttonStyle: "secondaryButton"
  },
  {
    title: "J'adhère",
    link: `${process.env.GATSBY_ADHERENT_URL}/signup`,
    isOutsideLink: true,
    buttonStyle: "mainButton"
  },
]
