import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    paddingTop: '3%'
  },
  footer: {
    color: '#838383',
    borderTop: 'solid 0.1rem #838383',
    borderBottom: 'solid 0.1rem #838383',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '2%',
    paddingBottom: '2%',
    width: '80%', // keep for article details page layout
    margin: '3rem 10% 0 10%',
    '@media (max-width:700px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  footerTitle: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:700px)': {
      paddingTop: '2%'
    },
  },
  title: {
    paddingBottom: '3%',
  },
  contactInfo: {
    display: 'flex',
    '@media (max-width:700px)': {
      justifyContent: 'center'
    },
  },
  contactInfoMail: {
    display: 'flex',
  },
  footerContact: {
    color: '#838383',
    textDecoration: 'none',
    fontSize: '0.875rem'
  },
  list: {
    listStyle: 'none',
    marginTop: 'auto',
    paddingLeft: '10%',
    fonSize: '0.875rem',
    width: "100%"
  },
  icon: {
    color: theme.palette.primary.mainBGColor
  },
  social: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  socialIcon: {
    color: theme.palette.primary.mainBGColor
  },
  menu: {
    '@media (max-width:700px)': {
      display: 'none'
    },
  },
  footerMenu: {
    columns: "2",
    webkitColumns: '50%',
    mozColumns: '50%'
  },
  listItem: {
    lineHeight: 1.8,
    color: '#838383',
    listStyle: 'none',
    paddingRight: '3%'
  },
  menuLink: {
    textDecoration: 'none'
  },
  copy: {
    color: '#838383',
    textAlign: 'center',
    paddingTop: '1%',
    paddingBottom: '5%',
    '@media (max-width:500px)': {
      fontSize: '0.7rem'
    },
  },
  legal: {
    color: '#838383',
    float: 'bottom'
  },
}));
