import React from 'react';
import { Link } from "gatsby";
import clsx from 'clsx';
import { Typography, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';
import { addProtocol } from '../../utils/format';

const CustomButton = ({ link, text, buttonStyle, isMaxWidth, isOutsideLink, type, children, disabled, action }) => {
  const classes = styles({ disabled });

  return (
    link
      ? isOutsideLink
        ? <a href={addProtocol(link, "https")} target="_blank" rel="noreferrer" className={classes.linkColor}>
          <Grid container justify="center" alignItems="center" className={clsx(classes[buttonStyle], classes.root, classes.button)}>
            {text}
          </Grid>
        </a>
        : <Link to={link} className={classes.linkColor}>
          <Grid container justify="center" alignItems="center" className={clsx(classes[buttonStyle], classes.root, classes.button)}>
            {text}
          </Grid>
        </Link>
      : <Button variant="contained"
        className={classes[buttonStyle]}
        classes={{ root: isMaxWidth ? classes.root : classes.button }}
        type={type}
        disabled={disabled}
        onClick={action && action}
      >
        <Typography className={classes.linkColor}>{children}</Typography>
      </Button>
  )
}

export default CustomButton

CustomButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['mainButton', 'secondaryButton']),
  isMaxWidth: PropTypes.bool,
  isOutsideLink: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

CustomButton.defaultProps = {
  buttonStyle: 'mainButton',
  isMaxWidth: false,
  isOutsideLink: false,
  disabled: false
};
