import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appbar: {
    borderBottom: `1px solid ${fade(theme.palette.primary.main, .1)}`
  },
  grow: {
    flexGrow: 1
  },
  root: {
    minHeight: 0,
    padding: 'auto .5vw',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    },
  },
  logo: {
    height: 70,
    aspectRatio: 16 / 9,
    paddingBottom: '0.8%'
  },
  logoDivider: {
    background: theme.palette.primary.main,
    height: 50,
    width: 1,
    margin: theme.spacing(2)
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: 'calc(1rem + .2vw)',
    color: theme.palette.primary.mainBGColor
  },
  home: {
    '&:hover': {
      background: 'none'
    }
  },
  menuList: {
    padding: 0,
    borderLeft: `1px solid ${fade(theme.palette.primary.main, .1)}`,
    borderRight: `1px solid ${fade(theme.palette.primary.main, .1)}`
  },
  menuButton: {
    textTransform: "initial",
    fontWeight: 'bold',
    width: '100%',
    borderRadius: 0,
    fontSize: 'calc(.7rem + .2vw)',
    padding: '0 .7vw',
    [theme.breakpoints.up('lg')]: {
      padding: '0 2vw',
    },
    '&:hover': {
      background: 'none'
    },
    '&:hover span': {
      color: theme.palette.primary.mainBGColor,
    }
  },
  desktopMenu: {
    display: 'none',
    borderRight: `1px solid ${fade(theme.palette.primary.main, 0.19)}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  subCategory: {
    padding: '.5rem 1rem',
    '&:hover': {
      background: theme.palette.secondary.mainBGColor
    },
    '&:hover span': {
      color: theme.palette.secondary.main
    }
  },
  search: {
    position: 'relative',
    borderRadius: 0,
    boxShadow: `0px 3px 6px ${fade(theme.palette.primary.main, .19)}`,
    backgroundColor: fade(theme.palette.primary.main, 0),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0),
    },
    marginLeft: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      width: '20vw',
    },
  },
  sectionMobile: {
    display: 'flex',
    margin: '.3rem',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  noHover: {
    '&:hover a span': {
      color: fade(theme.palette.primary.main, .75)
    }
  },
  buttonWrapper: {
    margin: '.5rem calc(.2rem + 3vw)'
  },
  underline: {
    textDecoration: 'underline',
  }
}));
