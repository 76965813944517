import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from "react-redux";

import Header from './Header'
import Footer from './Footer'
import TopLayout from '../utils/mui/TopLayout'
import CustomAlert from '../components/Modale';

const Layout = (props) => {

  const {
    popupOpen, popupMessage, popupError, popupId } = useSelector(state => ({
      popupOpen: state.getIn(['root', 'popupOpen']),
      popupMessage: state.getIn(['root', 'popupMessage']),
      popupError: state.getIn(['root', 'popupError']),
      popupId: state.getIn(['root', 'popupId']),
    }));

  const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    author
                    year
                    subtitle
                }
            }
        }
    `);

  return (
    <TopLayout>
      <Header metadata={data.site} />
      {props.children}
      <Footer metadata={data.site} />

      {/* The layout is used in each page, so the alert popup will appear wherever it's called from */}
      {popupOpen && <CustomAlert withError={popupError} id={popupId} message={popupMessage} />}

    </TopLayout>
  )
}

export default Layout