import React, { useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Dialog, Slide, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from './styles'
import { resetPopupStore } from '../../state/reducer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomAlert = ({ withError, id, message }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetPopupStore())
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby={id}
        aria-describedby={id}
        classes={{ paper: classes.root }}
      >
        <Grid container justify="center">
       
            <Box m={4}>
            <Typography variant="h2" className={classes.popupMessage}>{message}</Typography>
            </Box>

            {withError ? 
            <Grid container justify="center">
                 <CancelIcon onClick={handleClose} color="error" className={classes.cancelIcon} />
            </Grid>
            :
            <Grid container justify="center">
                <CheckCircleIcon onClick={handleClose} className={classes.checkIcon} />
            </Grid>
          }

        </Grid>
      </Dialog>
    </div>
  );
}

export default CustomAlert

CustomAlert.propTypes = {
  withError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

CustomAlert.defaultProps = {
  withError: false,
};
