import React from 'react';
import styles from './styles';
import { Link } from 'gatsby'
import { Box, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { menuCategories } from '../../utils/menuCategories';

const Footer = ({ metadata }) => {
  const classes = styles();

  return (
    <div >
      <div className={classes.footer}>
        <Box className={classes.info}>
          <div className={classes.footerTitle}>
            <Typography variant="h6" component="h5" className={classes.title}>Nos coordonnées</Typography>
          </div>

          <div className={classes.contactInfo}>

            <RecentActorsIcon fontSize='default' className={classes.icon} />
            <div>
              <ul className={classes.list}>
                <li>SAIIL - Syndicat Autonome des Infirmières et Infirmiers Libéraux</li>
                <br />
                <li>6 rue Delannoy</li>
                <li>BAT A- App 48</li>
                <li>59160 LOMME</li>
              </ul>
            </div>
          </div>

          <div className={classes.contactInfoMail}>
            <AlternateEmailIcon fontSize='small' className={classes.icon} />
            <Typography className={classes.list} component="p"><a href="mailto:syndicatil59@orange.fr" className={classes.footerContact}> syndicatil59@orange.fr</a></Typography>
          </div>

          <div className={classes.contactInfoMail}>
            <PhoneIcon fontSize='default' className={classes.icon} />
            <Typography className={classes.list} component="p"><a href="tel:+33642799606" className={classes.footerContact}>06 42 79 96 06</a></Typography>
          </div>
        </Box>

        <Box className={classes.menu}>
          <div className={classes.footerTitle}>
            <Typography variant="h6" component="h5">Navigation</Typography>
          </div>

          <ul className={classes.footerMenu}>
            {menuCategories.map((category, key) => {
              const subCategories = category.subCategories;
              return (
                subCategories
                  ? (
                    <div key={key}>
                      {subCategories.map((categories, key) => {
                        return (

                          <Link key={`${key}-sub`} to={categories.subCatLink} className={classes.menuLink}>
                            <li className={classes.listItem}>{categories.name}</li>
                          </Link>

                        )
                      })}

                    </div>
                  ) : (
                    <Link key={key} to={category.link} className={classes.menuLink}>
                      <li className={classes.listItem}>{category.title}</li>
                    </Link>
                  )
              );
            })}
          </ul>

        </Box>

        <Box className={classes.social}>
          <div className={classes.footerTitle}>
            <Typography variant="h6" component="h5" className={classes.title}>Nous suivre</Typography>
          </div>
          <div className={classes.iconGroup}>
            {/* Do not use Link from Gatsby for external links */}
            <a href="https://www.youtube.com/channel/UC2dhiIeFboM73MBO3MMSUTQ" target="_blank" rel="noreferrer noopener">
              <YouTubeIcon fontSize='large' className={classes.socialIcon} />
            </a>
            <a href="https://m.facebook.com/pages/category/Product-Service/Syndicat-des-Infirmiers-Lib%C3%A9raux-du-Nord-106360884726853/" target="_blank" rel="noreferrer noopener">
              <FacebookIcon fontSize='large' className={classes.socialIcon} />
            </a>
            <a href="https://www.linkedin.com/in/syndicat-des-infirmiers-libéraux-du-nord-42b755212/" target="_blank" rel="noreferrer noopener">
              <LinkedInIcon fontSize='large' className={classes.socialIcon} />
            </a>
          </div>
        </Box>
      </div>
      <footer className={classes.copy}>
        <p>{metadata.siteMetadata.title} &copy; {metadata.siteMetadata.year} - Réalisé par {metadata.siteMetadata.author} - <Link to="/legal" className={classes.legal}>Mentions légales</Link></p>
      </footer>
    </div>
  );
}

export default Footer
