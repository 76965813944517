import { CONTENTIEUX, JURIDIQUE, TRACABILITE } from '../constants';

export const extractFirstElementOfArray = (array) => {
  const [first, ...rest] = array;
  return {
    first,
    rest
  }
};


export const capitalizeString = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
};


/**
 * Check if the object has at least 1 empty value
 * @param {object} object - The object to check for empty values
 * @returns true if one of the object keys is empty
 */
export const hasNoEmptyValue = (object) => {
  return Object.values(object).every(x => !!x)
}


/**
 * Format the contact form's labels from field id
 * @param {string} field - the field value to format
 * @returns the formatted label
 */
export const switchLabel = (field) => {
  switch (field) {
    case 'lastName':
      return 'Nom';
    case 'firstName':
      return 'Prénom';
    case 'address':
      return 'Adresse du cabinet';
    case 'city':
      return 'Ville';
    case 'zipCode':
      return 'Code postal';
    case 'phone':
      return 'Numéro de téléphone';
    default:
      return field
  }
}


export const renderDocumentPagesStaticData = (data, section) => {
  const {
    traceabilityTitle, traceabilityImage, traceabilityIntro, legalIntro, legalTitle, legalCoverImage,
    litigationsTitle, litigationsImage, litigationsIntro, reglementationsTitle, reglementationsImage, reglementationsIntro
  } = data.texts.frontmatter;

  const switchPageInfos = (section) => {
    switch (section) {
      case 'tracabilite':
        return {
          title: traceabilityTitle,
          intro: traceabilityIntro,
          image: traceabilityImage.childImageSharp.gatsbyImageData
        }
      case 'juridique':
        return {
          title: legalTitle,
          intro: legalIntro,
          image: legalCoverImage.childImageSharp.gatsbyImageData
        }

      case 'contentieux':
        return {
          title: litigationsTitle,
          intro: litigationsIntro,
          image: litigationsImage.childImageSharp.gatsbyImageData
        }
      case 'reglementations':
        return {
          title: reglementationsTitle,
          intro: reglementationsIntro,
          image: reglementationsImage.childImageSharp.gatsbyImageData
        }
      default:
        return section
    }
  }
  return switchPageInfos(section);
}

export const sortArrayOfObjectsOnFieldName = (arr, field) => {
  return arr?.sort((a, b) => a[field] - b[field])
}

/**
 * @param {string} section - the docs' page name
 * @returns the path of the equivalent page on the adherents website
 */
export const switchAdherentPath = (section) => {
  switch (section) {
    case JURIDIQUE:
      return 'legalFrame'
    case CONTENTIEUX:
      return 'litigation'
    case TRACABILITE:
      return 'traceability'
    default:
      break;
  }
}
