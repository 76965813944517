import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    background: fade(theme.palette.secondary.main, .6),
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    borderRadius: 183,
    width: '90%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`
    },
  },
  popupMessage: {
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center'
  },
  cancelIcon: {
    fontSize: 70
  },
  checkIcon: {
    color: 'green',
    fontSize: 70
  }
}));