import React from 'react';
import PropTypes from 'prop-types';
import { SwipeableDrawer, List, ListSubheader, ListItem, ListItemText, Divider, Grid, Box } from '@material-ui/core';
import { Button } from "gatsby-theme-material-ui";

import styles from './styles';
import clsx from 'clsx';
import CustomButton from '../../CustomButton/customButton';
import { addProtocol } from '../../../utils/format';

export const CustomSwipeableDrawer = ({ items, toggleDrawer, open, registerLinks }) => {
  const classes = styles();

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
    >
      <List>
        {items.map((item, key) => (
          <Grid container direction="column" key={key} alignItems="flex-start">
            {item.subCategories
              ? <>
                <ListItem>
                  <ListItemText className={classes.menuButton}>{item.title}</ListItemText>
                </ListItem>
                {item.subCategories.map((subCategory, key) => (
                  <Grid container direction="column" key={key} alignItems="flex-start" className={clsx(classes.withHover, classes.sub)}>
                    <Button to={subCategory.subCatLink} className={classes.menuSubcategory}>
                      {subCategory.name}
                    </Button>
                  </Grid>
                ))}
              </>
              : (
                <Box className={clsx(classes.withHover, classes.cat)}>
                  <Button to={item.link} className={classes.menuSubcategory}>
                    <b>{item.title}</b>
                  </Button>
                </Box>
              )
            }
          </Grid>
        ))}
      </List>
      <Divider />
      <List
        subheader={(
          <ListSubheader component="div" id="nested-list-subheader">
            Adhésion
          </ListSubheader>
        )}
      >
        <Grid container direction="column" alignItems="flex-start">
          {registerLinks.map((link, key) => (
            link.isOutsideLink
              ? (
                <Box m={1} className={classes.sub} key={key}>
                  <CustomButton link={addProtocol(link.link, "https")} text={link.title} buttonStyle={link.buttonStyle} isOutsideLink={link.isOutsideLink} />
                </Box>
              )
              : (
                <Box className={clsx(classes.noHover, classes.cat)} key={key}>
                  <Button to={link.link} key={key} className={clsx(classes.menuButton, classes.menuSubcategory, classes.underline)}>
                    {link.title}
                  </Button>
                </Box>
              )
          ))}
        </Grid>
      </List>
    </div >
  );

  return (
    <React.Fragment key="left">
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        {list('left')}
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default CustomSwipeableDrawer;


CustomSwipeableDrawer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      subCatLink: PropTypes.string.isRequired
    })),
  })).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  registerLinks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isOutsideLink: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.oneOf(["mainButton", "secondaryButton"])
  })).isRequired
}

CustomSwipeableDrawer.defaultProps = {
  registerLinks: PropTypes.arrayOf(PropTypes.shape({
    buttonStyle: "mainButton"
  }))
}
