/**
 * @param {string} url - l'url à checker
 * @param {*} protocol - le protocole à ajouter
 * @returns l'url précédée du protocole choisi
 */
export const addProtocol = (url, protocol) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `${protocol}://${url}`;
  }
  return url;
}


/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the date formatted as "2021-05-05"
 */
export const formatTimestampToDateWithDashes = (date) => {
  return new Date(date).toISOString().split('T')[0];
}

export const formatHour = (date) => {
  const hour = new Date(date).getHours()
  const minute = new Date(date).getMinutes();
  const hourMinute = minute === 0 ? (hour + 'h' + '00') : (hour + 'h' + minute);
  return hourMinute
}

/**
 * @param {string} date - date as "2021-05-13"
 * @returns Date as "13-05-201"
 */
export const formatDateToFrenchFormat = (date) => {
  const splittedEnglishDate = date?.split("-");
  const calendarDate = new Date(date).getDate();
  const day = calendarDate < 10 ? ('0' + calendarDate) : calendarDate;
  if (splittedEnglishDate) {
    return `${day}-${splittedEnglishDate[1]}-${splittedEnglishDate[0]}`
  }
}



/**
 *
 * @param {number} date - Timestamp
 * @param {number} numberOfDays
 * @returns - date + numberOfDays as timestamp
 */
export const addDaysToTimestamp = (date, numberOfDays) => {
  return date + 24 * 60 * 60 * 1000 * numberOfDays;
}
