import { fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    minWidth: '8rem',
    padding: '0 1rem',
  },
  mainButton: {
    backgroundColor: theme.palette.primary.mainBGColor,
    height: 30,
    borderRadius: 10,
    '&:hover': {
      transform: 'translateY(-2px)',
      transitionDuration: '0.2s',
      backgroundColor: theme.palette.primary.mainBGColor,
    }
  },
  secondaryButton: {
    backgroundColor: theme.palette.secondary.mainBGColor,
    height: 30,
    borderRadius: 10,
    '&:hover': {
      transform: 'translateY(-2px)',
      transitionDuration: '0.2s',
      backgroundColor: theme.palette.secondary.mainBGColor,
    }
  },
  linkColor: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: ({ disabled }) => disabled ? fade(theme.palette.primary.main, .3) : theme.palette.secondary.main
  }
}));