import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { getSearchLaunched, setSearchSkip } from '../../state/reducer';
import { SEARCH_SKIP } from '../../utils/constants';
import styles from './styles';

const Searchbar = () => {
  const classes = styles();
  const dispatch = useDispatch()

  const { searchValue } = useSelector(state => ({
    searchValue: state.getIn(['root', 'searchValue', 'value'])
  }));
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(searchValue)
  }, [searchValue])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value) {
      dispatch(getSearchLaunched({ value, skip: SEARCH_SKIP }))
      dispatch(setSearchSkip(SEARCH_SKIP));
    }
  }
 
  return (
    
      <form onSubmit={handleSubmit} >
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <IconButton type="submit">
              <SearchIcon/>
            </IconButton>
          </div>
          <div className={classes.SearchBar}>
            <input className={classes.inputSearchbar} 
              enterkeyhint="search"
              placeholder="Recherche"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => setValue(event.target.value.toLowerCase())}
              value={value} />
          </div>
        </div>
      </form>
  );
}

export default Searchbar;