import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import { AppBar, Toolbar, IconButton, Typography, Menu, Divider, Box } from '@material-ui/core';
import { Button } from "gatsby-theme-material-ui";

import Icon from '../Icons/Icon';
import CustomSwipeableDrawer from './CustomSwipeableDrawer';
import CustomButton from '../CustomButton/customButton';
import Searchbar from '../Searchbar';

import logo from '../../../static/logo.png';
import {
  faBars,
  faHome
} from '@fortawesome/free-solid-svg-icons'
import useStyles from './styles';
import { menuCategories, registerLinks } from '../../utils/menuCategories';
import { extractFirstElementOfArray } from '../../utils/services/format';

export const Header = ({ metadata }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(menuCategories.forEach(x => x.title));
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const formattedLinks = extractFirstElementOfArray(registerLinks);

  const toggleDrawer = () => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClick = (e, title) => {
    setAnchorEl({ [title]: e.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (category, key) => {
    const subCategories = category.subCategories;
    return (
      subCategories
        ? (
          <div key={key} className={classes.desktopMenu}>
            <Button aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => handleClick(e, category.title)}
              className={classes.menuButton}
            >
              {category.title}
            </Button>

            <Menu
              anchorEl={anchorEl && anchorEl[category.title]}
              id={category.title}
              getContentAnchorEl={null} // Keep to be able to set the anchor Origin 👇
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={anchorEl ? category.title === Object.keys(anchorEl).toString() : false}
              onClose={handleClose}
              elevation={0}
              PaperProps={{ style: { borderRadius: 0, transform: 'translateY(.5rem)' } }}
              MenuListProps={{ classes: { root: classes.menuList } }}
            >
              {subCategories.map((subCategory, key) => (
                <div key={key}>
                  <Button
                    to={subCategory.subCatLink}
                    onClick={handleClose}
                    className={clsx(classes.menuButton, classes.subCategory)}
                  >
                    {subCategory.name}
                  </Button>
                  <Divider />
                </div>
              ))}
            </Menu>
          </div>
        )
        : (
          <div key={key} className={classes.desktopMenu}>
            <Button
              to={category.link}
              onClick={handleClose}
              className={classes.menuButton}
            >
              {category.title}
            </Button>
          </div>
        )
    )
  };

  const renderMobileMenu = (
    <CustomSwipeableDrawer
      items={menuCategories}
      toggleDrawer={toggleDrawer}
      open={isMobileMenuOpen}
      registerLinks={registerLinks}
    />
  );

  return (
    <>
      <AppBar position="sticky" color="secondary" elevation={0} className={classes.appbar}>
        <Toolbar className={classes.desktopMenu}>
          <Link to="/">
            <img src={logo} alt="Saiil" className={classes.logo} />
          </Link>
          <Box className={classes.logoDivider}></Box>
          <Typography className={classes.subtitle}>
            <b>{metadata.siteMetadata.subtitle}</b>
          </Typography>
          <div className={classes.grow} />
          <div>
            <Button to={formattedLinks.first.link} className={clsx(classes.menuButton, classes.menuSubcategory, classes.underline)}>
              {formattedLinks.first.title}
            </Button>
          </div>
          <div>
            {formattedLinks.rest.map((link, key) => (
              <div className={classes.buttonWrapper} key={key}>
                <CustomButton
                  link={link.link}
                  text={link.title}
                  buttonStyle={link.buttonStyle}
                  isMaxWidth
                  isOutsideLink={link.isOutsideLink}
                />
              </div>
            ))}
          </div>
        </Toolbar>

        <Divider />

        <Toolbar variant="dense" classes={{ root: classes.root }}>
          <div className={classes.desktopMenu}>
            <Button to="/" className={classes.home}>
              <Icon icon={faHome} variant="secondary" size="2x" />
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <Button to="/" className={classes.home} style={{ padding: 0 }}>
              <img src={logo} alt="Saiil" className={classes.logo} />
            </Button>
          </div>

          {menuCategories.map((category, key) => renderMenu(category, key))}

          {/* Searchbar */}
          <div className={classes.search}>
            <Searchbar />
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls="menu-mobile"
              aria-haspopup="true"
              onClick={toggleDrawer()}
              color="inherit"
              onKeyDown={toggleDrawer()}
            >
              <Icon icon={faBars} variant="black" size="1x" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default Header;
