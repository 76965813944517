import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    width: 250,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '& span': {
      fontWeight: "bold",
      fontSize: ".9rem"
    }
  },
  menuSubcategory: {
    textTransform: "initial",
    fontSize: ".9rem",
    width: '100%',
    borderRadius: 0,
    justifyContent: "flex-start"
  },
  withHover: {
    width: '100%',
    '&:hover a': {
      background: theme.palette.secondary.mainBGColor,
      color: theme.palette.secondary.main
    }
  },
  noHover: {
    '&:hover a': {
      background: 'none'
    },
    '&:hover a span': {
      color: fade(theme.palette.primary.main, .75)
    }
  },
  cat: {
    paddingLeft: theme.spacing(1),
  },
  sub: {
    paddingLeft: theme.spacing(4),
  },
  underline: {
    textDecoration: 'underline',
  }
}));